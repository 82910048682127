import React, { useState, useEffect } from 'react';
import db from '../../Db/db';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';

const Calculatrice = () => {
    const [agences, setAgence] = useState([]);
    const [energies, setEnergies] = useState([]);
    const [conso, setConso] = useState([]);
    const [adem, setAdem] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data = await db.agences.toArray();
            setAgence(data);
            const data2 = await db.energies.toArray();
            setEnergies(data2);
            const data3 = await db.conso.toArray();
            setConso(data3);
            const data4 = await db.adem.toArray();
            setAdem(data4);
        }
        fetchData();
    }, []);



    const [formData, setFormData] = useState({
        soct: '',
        dist: '',
        ratio: false,
        choice: '',
        poid: '',
        mlin: '',
        nrj1: '',
        con1: '',
        fem1: '',
        nrj2: '',
        con2: '',
        fem2: ''
    });

    const [results, setResults] = useState(null);
    const [ratioChecked, setRatioChecked] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === 'ratio') {
            setRatioChecked(checked);
        } else if (name === 'choice') {
            setSelectedChoice(value);
        }

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });

       

        
        if ( name === 'nrj1') {
            updateCon1(name === 'soct' ? value : formData.soct, name === 'nrj1' ? value : formData.nrj1);
        }

        if ( name === 'nrj2') {
            updateCon2(name === 'soct' ? value : formData.soct, name === 'nrj2' ? value : formData.nrj2);
        }

        
    }

    const updateCon1 = (selectedAgence, selectedEnergie) => {
        if (!selectedAgence || !selectedEnergie) return;

        let energiesSelect = energies.find(e => e.cods === selectedEnergie)
        let facteur = adem.find(a => a.ademId === energiesSelect.adem)

        let consommation = conso.find(c => c.agence === selectedAgence && c.energie === selectedEnergie);

        if (!consommation) {
            consommation = conso.find(c => c.agence === 'HAU' && c.energie === selectedEnergie);
        }

        if (consommation && facteur) {
            setFormData({
                ...formData,
                nrj1: selectedEnergie,
                con1: consommation.valeur,
                fem1 : facteur.Valu
            });
        }else{
            setFormData({
                ...formData,
                nrj1: selectedEnergie,
                con1: '0.00',
                fem1: '0.00'
            });
        }
        
    };

    

    const updateCon2 = (selectedAgence, selectedEnergie) => {
        if (!selectedAgence || !selectedEnergie) return;

        let energiesSelect = energies.find(e => e.cods === selectedEnergie)
        let facteur = adem.find(a => a.ademId === energiesSelect.adem)

        let consommation = conso.find(c => c.agence === selectedAgence && c.energie === selectedEnergie);

        if (!consommation) {
            consommation = conso.find(c => c.agence === 'HAU' && c.energie === selectedEnergie);
        }
        if (consommation) {
            setFormData({
                ...formData,
                nrj2: selectedEnergie,
                con2: consommation.valeur,
                fem2 : facteur.Valu
            });
        }else{
            setFormData({
                ...formData,
                nrj2: selectedEnergie,
                con2: '0.00',
                fem2: '0.00'
            });
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { soct, dist, ratio, choice, poid, mlin, nrj1, con1, fem1, nrj2, con2, fem2 } = formData;

        let emission1 = (dist * con1 / 100) * fem1;
        let unit1 = "kg.CO²";

        if (ratio) {
            if (choice === 'poidM') {
                emission1 *= (poid / 25);
                unit1 = "kg.CO².T";
            } else if (choice === 'metr') {
                emission1 *= (mlin / 13.2);
                unit1 = "kg.CO².ML";
            }
        }

        let emission2 = (dist * con2 / 100) * fem2;
        let unit2 = "kg.CO²";

        if (ratio) {
            if (choice === 'poidM') {
                emission2 *= (poid / 25);
                unit2 = "kg.CO².T";
            } else if (choice === 'metr') {
                emission2 *= (mlin / 13.2);
                unit2 = "kg.CO².ML";
            }
        }

        const gain = Math.abs(emission1 - emission2);
        const gain_unit = unit1 === unit2 ? unit1 : "";
        const percent_gain = (gain * 100) / Math.max(emission1, emission2);

        setResults({
            nrj1, emission1, unit1,
            nrj2, emission2, unit2,
            gain, gain_unit, percent_gain
        });
    };

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Emission de CO²"/>
                </div>
                <div className="p-4 h-[85%] w-full">
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-8">
                            <div>
                                <h3 className="text-xl font-semibold text-center text-marineLogo mb-8">Caractéristiques du transport</h3>
                                <div className="space-y-4">
                                    <div className="flex space-x-4">
                                        <div className="w-1/2 space-y-4">
                                            <div className="flex items-center space-x-2">
                                                <label htmlFor="soct" className="w-1/3">Agence :</label>
                                                <select
                                                    name="soct"
                                                    id="soct"
                                                    className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo"
                                                    onChange={handleChange}
                                                >
                                                    <option value=""></option>
                                                    {agences.map((agence, index) => (
                                                        <option key={index} value={agence.code}>{agence.nom}</option>
                                                    ))}
                                                </select>
                                                <span className="w-1/6"></span>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <label htmlFor="dist" className="w-1/3">Distance à parcourir :</label>
                                                <input type="number" name="dist" id="dist" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" step="1" onChange={handleChange} />
                                                <span className="w-1/6">KM</span>
                                            </div>
                                        </div>
                                        <div className="w-1/2 space-y-4">
                                            <div className="flex items-center space-x-2">
                                                <label htmlFor="ratio" className="w-1/3">Appliquer un ratio :</label>
                                                <input type="checkbox" name="ratio" id="ratio" className="mr-2" onChange={handleChange} />
                                                <select name="choice" id="choice" className={`flex-1 w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo ${!ratioChecked ? 'hidden' : ''}`} onChange={handleChange}>
                                                    <option value=""></option>
                                                    <option value="poidM">Poids</option>
                                                    <option value="metr">Métrage linéaire</option>
                                                </select>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <label htmlFor="poid" className={`w-1/3 ${!ratioChecked || selectedChoice !== 'poidM' ? 'hidden' : ''}`}>Poids de la marchandise :</label>
                                                <input type="number" name="poid" id="poid" className={`flex-1 w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo ${!ratioChecked || selectedChoice !== 'poidM' ? 'hidden' : ''}`} step="0.01" min="0.00" max="25.00" onChange={handleChange} />
                                                <span className={`w-1/6 ${!ratioChecked || selectedChoice !== 'poidM' ? 'hidden' : ''}`}>T.</span>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <label htmlFor="mlin" className={`w-1/3 ${!ratioChecked || selectedChoice !== 'metr' ? 'hidden' : ''}`}>Métrage linéaire :</label>
                                                <input type="number" name="mlin" id="mlin" className={`flex-1 w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo ${!ratioChecked || selectedChoice !== 'metr' ? 'hidden' : ''}`} step="0.01" min="0.00" max="13.20" onChange={handleChange} />
                                                <span className={`w-1/6 ${!ratioChecked || selectedChoice !== 'metr' ? 'hidden' : ''}`}>ML</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-center text-marineLogo mb-8">Énergies disponibles</h3>
                                <div className="flex space-x-4">
                                    <div className="w-1/2 space-y-4">
                                        <div className="flex items-center space-x-2">
                                            <label htmlFor="nrj1" className="w-1/3">Énergie 1 :</label>
                                            <select name="nrj1" id="nrj1" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" onChange={handleChange}>
                                                <option value=""></option>
                                                {energies.map((energie, index) => (
                                                    <option key={index} value={energie.cods}>{energie.libl}</option>
                                                ))}
                                            </select>
                                            <span className="w-1/6"></span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <label htmlFor="con1" className="w-1/3">Consommation moyenne :</label>
                                            <input type="number" name="con1" id="con1" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" step="0.01" value={formData.con1} onChange={handleChange} />
                                            <span className="w-1/6">l. / 100km</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <label htmlFor="fem1" className="w-1/3">Facteur d'émission :</label>
                                            <input type="number" name="fem1" id="fem1" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" step="0.01" value={formData.fem1} onChange={handleChange} />
                                            <span className="w-1/6">kg.CO²</span>
                                        </div>
                                    </div>
                                    <div className="w-1/2 space-y-4">
                                        <div className="flex items-center space-x-2">
                                            <label htmlFor="nrj2" className="w-1/3">Énergie 2 :</label>
                                            <select name="nrj2" id="nrj2" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" onChange={handleChange}>
                                                <option value=""></option>
                                                {energies.map((energie, index) => (
                                                    <option key={index} value={energie.cods}>{energie.libl}</option>
                                                ))}
                                            </select>
                                            <span className="w-1/6"></span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <label htmlFor="con2" className="w-1/3">Consommation moyenne :</label>
                                            <input type="number" name="con2" id="con2" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" step="0.01" value={formData.con2} onChange={handleChange} />
                                            <span className="w-1/6">l. / 100km</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <label htmlFor="fem2" className="w-1/3">Facteur d'émission :</label>
                                            <input type="number" name="fem2" id="fem2" className="w-2/3 p-2 border border-marineLogo rounded-md hover:border hover:border-vertLogo" step="0.01" value={formData.fem2} onChange={handleChange} />
                                            <span className="w-1/6">kg.CO²</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="bg-vertLogo text-blanc py-2 px-4 rounded-md hover:bg-blanc hover:text-vertLogo border border-vertLogo">Calculer</button>
                            </div>
                            {results && (
                                <div>
                                    <h3 className="text-xl font-semibold text-center text-marineLogo">Résultats</h3>
                                    <div className="flex space-x-4">
                                        <div className="w-1/2 border border-vertLogo p-4 rounded-md text-marineLogo">
                                            <p className="font-bold">Énergie 1: <span>{results.nrj1}</span></p>
                                            <p>Émissions de CO²: <span className='text-vertLogo font-bold'>{results.emission1.toFixed(2)} {results.unit1}</span></p>
                                        </div>
                                        <div className="w-1/2 border border-vertLogo p-4 rounded-md text-marineLogo">
                                            <p className="font-bold">Énergie 2: {results.nrj2}</p>
                                            <p>Émissions de CO²: <span className='text-vertLogo font-bold'>{results.emission2.toFixed(2)} {results.unit2}</span></p>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <p className="text-roseLogo italic">Votre gain total est de <span className="text-vertLogo font-bold">{results.gain.toFixed(2)} {results.gain_unit}</span>, soit <span className="text-vertLogo font-bold">{results.percent_gain.toFixed(2)}%</span>.</p>
                                        <p className="text-roseLogo italic">L'énergie la plus efficace en termes de réduction des émissions de CO² est <span className="text-vertLogo font-bold">{results.emission1 > results.emission2 ? results.nrj2 : results.nrj1}</span>.</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Calculatrice;
