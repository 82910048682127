import React, { useState, useEffect } from 'react';
import axios from "axios";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';

import FormMergeClient from "../Containers/formulaire/FormMergeClient"

function Conso({ url }) {

    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [consoData, setConsoData] = useState([]);
    const [submitData, setSubmitData] = useState([]);

    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData)
        conso(submitFilterData);

    };

    useEffect(() => {
        conso(submitData);
     }, [url]);

     function disabled(parent) {
        axios.put(url+"client/disabledPar",{
            parent: parent
        }, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        conso();
      }

      function conso(submitData) {
        axios.get(url+'client/allParents/', {
            params: {
                searchTerm: submitData.searchTerm,
                commercial: submitData.commercial,
                selectedAgence: submitData.selectedAgence

            },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {       
            setConsoData(res.data.data);
        })
        .catch(error=>{
            console.log(error);
            
        });
      }

      function onSubmitSuccess (){
        conso(submitData);
      }


    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Consolidation Clients" btn={false} onSubmitFilter={handleSubmit} />
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[80%] h-full font-bahn'>
                        <div className='h-[4%] w-full'>
                            <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[80%] border-collapse ">
                                <thead className='h-[6%] max-h-1.5'>
                                    <tr className='bg-marineLogo text-blanc'>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Client Parents</th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Clients enfants</th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Supprimer</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className='h-[80%] w-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>                
                            <table className="table-fixed h-[96%] border-collapse items-center bg-transparent w-[80%]">
                                <tbody className='overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[94%] min-h-[50%]'>
                                    {consoData.map(conso => (
                                        <tr key={conso.parent} className='max-h-[10%] min-h-[5%]  border border-b-1 border-l-0 border-r-0 border-marineLogo'>
                                            <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-marineLogo">{conso.parent}</td>
                                            <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">
                                            {
                                                conso.children.map(child => (
                                                    <ul>
                                                        <li key={child.child}>{child.child} {child.agency}</li>
                                                    </ul>
                                                ))
                                            }
                                            </td>
                                            <td className='group'>
                                                <svg onClick={() => disabled(conso.parent)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:stroke-roseLogo w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                                </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='w-[20%] h-full flex justify-end'>
                        <FormMergeClient url={url} token={userInfo.token} onSubmitSuccess={onSubmitSuccess} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Conso;