import React, { useState, useEffect, useRef  } from 'react';
import Select from 'react-select';
import db from '../../Db/db';

export default function Filtre({ onSubmitFilter, title }) {
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAgence, setSelectedAgence] = useState('');

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [startDate, setStartDate] = useState(new Date(currentYear , 0, 2));
    const [endDate, setEndDate] = useState(currentDate);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [commercial, setCommercial] = useState([]);

    const [agencesData, setagencesData] = useState([]);
    const [activitesData, setactivitesData] = useState([]);
    const [clientData, setclientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        async function fetchData() {
            const data = await db.agences.toArray();
            setagencesData(data);
            const data2 = await db.activite.toArray();
            setactivitesData(data2);
            const data3 = await db.clients.toArray();
            setclientData(data3);
            const data4 = await db.team.toArray();
            setTeamData(data4);
        }
  
        fetchData();
    }, []);

    const clientsCommercialData = clientData/*.filter(client => client.commercial == userInfo.user.idUser)*/ || [];

    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
        
    };

    const saveSearchTerm = (searchTerm) => {
        const searchHistory = JSON.parse(localStorage.getItem('histo')) || [];
        const termIndex = searchHistory.findIndex(term => term === searchTerm);

        if (termIndex === -1) {
            searchHistory.push(searchTerm);
            localStorage.setItem('histo', JSON.stringify(searchHistory));
        }
    };

    const handleAgenceChange = (e) => {
        setSelectedAgence(e.target.value);
    };

    const handleCommercialChange = (e) => {
        setCommercial(e.target.value);
    };

    
    const handleStartDateChange = (e) => {
        const newDate = new Date(e.target.value); 
        setStartDate(newDate);
    };
    
    const handleEndDateChange = (e) => {
        const newDate = new Date(e.target.value);
        setEndDate(newDate);
    };
    const handleDateFocus = (e) => {
        e.target.value = "";
    };

    const handleToggleSidebar = () => {
        
        if(isSidebarOpen){
            setSearchTerm('')
        }
        setIsSidebarOpen(!isSidebarOpen);
        setSelectedAgence('')
        setSelectedOptions([])
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveSearchTerm(searchTerm);
        const filterData = {
            searchTerm,
            selectedAgence,
            selectedOptions,
            startDate,
            endDate,
            commercial
        };
        onSubmitFilter(filterData)
        if(isSidebarOpen){
            setIsSidebarOpen(!isSidebarOpen);
        }
        
    };

    useEffect(() => {
        if (inputRef.current) {
            const longestOption = clientsCommercialData.reduce((prev, current) => {
                const optionText = `${current.codeClient} ${current.nomClient} ${current.codeAgence}`;
                return optionText.length > prev ? optionText.length : prev;
            }, 0);
            inputRef.current.style.width = `${longestOption * 8}px`; 
        }
    }, [clientsCommercialData]);

    return (
        <div className='absolute top-[5%]  md:top-[8%] left-[60%] pr-8'>
            <form className="flex items-center z-10" onSubmit={handleSubmit}>
                <div className='flex flex-column'>
                    <div className='relative '>
                        
                        <div className='flex '>
                            <label htmlFor='simple-search' className='sr-only'>
                                Search
                            </label>
                            <input
                                type='text'
                                list='clientsList'
                                name='clientsList'
                                id='simple-search'
                                className=' md:text-[60%] max-w-[500px] md:max-w-[300px] border bg-blanc border-marineLogo text-[11px] rounded group-hover:ring-vertLogo group-hover:border-vertLogo block pl-10'
                                placeholder='Nom client'
                                value={searchTerm}
                                onChange={handleSearchChange}
                                ref={inputRef}
                                style={{
                                    backgroundImage:
                                        "url('data:image/svg+xml,%3Csvg aria-hidden=\"true\" className=\"w-2 h-2 group-hover:fill-vertLogo\" fill=\"%23113577\" viewBox=\"0 0 20 20\" xmlns=\"http://www.w3.org/2000/svg\"%3E%3Cpath fillRule=\"evenodd\" d=\"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z\" clipRule=\"evenodd\"%3E%3C/path%3E%3C/svg%3E')",
                                    backgroundPosition: 'left center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '1.2rem',
                                    paddingLeft: '2rem',
                                }}
                            />
                            <datalist id='clientsList'>
                                {clientsCommercialData.map(c => (
                                    <option key={c.codeClient + ' / ' + c.codeAgence} value={c.codeClient + ' / ' + c.nomClient + ' / ' + c.codeAgence}>{c.codeClient} {c.nomClient} {c.codeAgence}</option>
                                ))}
                                </datalist>
                            {!isSidebarOpen && (
                                <button
                                    type="submit"
                                    className="bg-vertLogo p-2 ml-2 text-sm font-medium rounded  hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                                >
                                    <svg className="w-5 h-5 group-hover:stroke-vertLogo" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                    <span className="sr-only">Search</span>
                                </button>
                            )}
                            <button
                                type='button'
                                onClick={handleToggleSidebar}
                                className='bg-vertLogo p-2 ml-2 text-sm text-blanc font-medium rounded group hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo'
                            >

                                {isSidebarOpen ? 'Annuler' : '+'}
                            </button>
                        </div>
                        {isSidebarOpen && (
                            <div className="absolute top-12 left-0 sidebar bg-marineLogo p-4 rounded">
                            {(title !== 'Consolidation Clients'  && title !== "Affectations Clients" && title !== "Désactiver prospect") && (  
                                <div>
                                    <div className="flex justify-between mb-4">
                                        <div className="flex items-center">
                                            <label htmlFor="date-du" className="text-sm text-blanc w-20">DU </label>
                                            <input
                                                type="date"
                                                id="date-du"
                                                className="border border-marineLogo text-sm rounded w-32 p-1"
                                                value={startDate.toISOString().split('T')[0]}
                                                onFocus={handleDateFocus}
                                                onChange={handleStartDateChange}
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <label htmlFor="date-au" className="text-sm text-blanc w-8"> AU </label>
                                            <input
                                                type="date"
                                                id="date-au"
                                                className="border border-marineLogo text-sm rounded w-32 p-1"
                                                value={endDate.toISOString().split('T')[0]}
                                                onFocus={handleDateFocus}
                                                onChange={handleEndDateChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='flex mb-4'>
                                        <label className='w-1/3 text-sm text-blanc'>ACTIVITE : </label>
                                        <div className='w-2/3'>
                                            <Select
                                                className='text-sm w-full'
                                                isMulti
                                                options={activitesData}
                                                value={selectedOptions}
                                                onChange={selected => setSelectedOptions(selected)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                                <div className='flex mb-4'>
                                    <label className='w-1/3 text-[80%] text-blanc'>AGENCE : </label>
                                    <div className='w-2/3'>
                                        <select
                                            id="agence-select"
                                            className="border border-marineLogo bg-blanc text-sm rounded p-1 w-full"
                                            value={selectedAgence}
                                            onChange={handleAgenceChange}
                                        >
                                            <option value="" disabled>Agence</option>
                                            {agencesData.map(a => (
                                                <option key={a.code} value={a.code}>{a.nom}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='flex mb-4'>
                                    <label className='w-1/3 text-[80%] text-blanc'>COMMERCIAL : </label>
                                    <div className='w-2/3'>

                                    <select
                                        id="commercial-select"
                                        className="border border-marineLogo bg-blanc text-sm rounded p-1 w-full"
                                        value={commercial}
                                        placeholder="Commercial"
                                        onChange={handleCommercialChange}
                                    >
                                        <option value=""></option>
                                        {teamData.map(t => {
                                            let isDisabled = false;

                                            if (userInfo.user.role === 'Commercial') {
                                                isDisabled = t.idUser != userInfo.user.idUser;
                                                if (userInfo.user.idUser === 18) { // [ROLE] FAURIE NICOLAS -> MENDIBOURE IONA
                                                    isDisabled = t.idUser != userInfo.user.idUser && t.idUser != 50;
                                                }
                                            } else if (userInfo.user.role.includes('Assistant')) {
                                                isDisabled = t.idUser != (userInfo.user.role.split(' '))[1];
                                            }

                                            return (
                                                <option 
                                                    key={t.name} 
                                                    value={t.idUser} 
                                                    disabled={isDisabled}
                                                >
                                                    {t.name} {t.firstName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    </div>
                                </div>

                                <div className='flex justify-end group'>
                                    <button
                                        type="submit"
                                        className="bg-vertLogo p-2 ml-2 text-sm font-medium rounded  hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                                    >
                                        <svg className="w-5 h-5 group-hover:stroke-vertLogo" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                        </svg>
                                        <span className="sr-only">Search</span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}
